export enum languages {
  en = "en",
  da = "da"
}

export const error404Info = {
  errorCode: 404,
  errorMessage: "404-error-message",
  errorSuggestion: "404-error-suggestion"
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
