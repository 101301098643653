import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ReactComponent as BagstageLogo } from "../assets/bagstage_logo_small.svg";
import StandardLinkButton from "../components/UI/buttons/StandardLinkButton";
import PageWrapper from "../components/UI/PageWrapper";
import SubPageWrapper from "../components/UI/SubPageWrapper";
import errorStyles from "../styles/errorPage.module.css";
import { error404Info } from "../utils/commonUtil";
import Header from "../components/UI/Header";

export const ErrorPage = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { errorCode, errorMessage, errorSuggestion } = error404Info;

  const getPreviousPath = () => {
    const { pathname } = location;
    const pathSegments = pathname.split("/").filter(Boolean);

    if (pathSegments.length > 1) {
      return `/${pathSegments.slice(0, -1).join("/")}`;
    }
    return "/";
  };

  return (
    <PageWrapper>
      <Header />

      <SubPageWrapper>
        <div className={errorStyles.errorContainer}>
          <BagstageLogo className={errorStyles.bagstageLogo} />

          <div className={errorStyles.errorWrapper}>
            <p className={errorStyles.errorCode}>{errorCode}</p>
            <div className={errorStyles.errorTextButtonWrapper}>
              <div className={errorStyles.errorTextWrapper}>
                <p className={errorStyles.errorMessage}>{t(errorMessage)}</p>
                <p className={errorStyles.errorSuggestion}>
                  {t(errorSuggestion)}
                </p>
              </div>
              <StandardLinkButton
                to={getPreviousPath()}
                variant="primary"
                classes={errorStyles.previousButton}
              >
                {t("back-to-previous-page")}
              </StandardLinkButton>
            </div>
          </div>
        </div>
      </SubPageWrapper>
    </PageWrapper>
  );
};
