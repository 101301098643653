import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./pages/HomePage";
import { ErrorPage } from "./pages/ErrorPage";
import RedirectToUserManagement from "./pages/RedirectToUserManagement";
import RedirectToArrivalAnalyst from "./pages/RedirectToArrivalAnalyst";

import "./service/translations/translationService";

const router = createBrowserRouter([
  { path: "/", element: <HomePage /> },
  { path: "/user-management", element: <RedirectToUserManagement /> },
  { path: "/arrival-analyst", element: <RedirectToArrivalAnalyst /> },
  { path: "*", element: <ErrorPage /> }
]);

const App = () => {
  return (
    <main>
      <RouterProvider router={router} />
    </main>
  );
};
export default App;
